<template>
    <div>
        <div style="margin-top:20px"
            v-html="this.tips">
        </div>
        <img style="width:100%" id="barcode" />
        <div class="bind-msg-div" v-show="showBindConfirmMessage">{{ $t("barcode.bindConfirmMessage") }}</div>
    </div>
</template>

<script>
import { Toast } from "vant";
import JsBarcode from 'jsbarcode'
// import Canvas from 'canvas'
import { sendLineInfo } from "@/api/eformApi";

export default {
    name: "QRcodes",
    props: {
        brandConfig: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            custId: '',
            brandName: '',
            storeId: '',
            lang: '',
            brand: '',
            country: '',
            txNo: '',
            tpid: "",
            showBindConfirmMessage: false,
            bindConfirmMessage: this.$route.params.bindConfirmMessage
        };
    },
    created() {
        console.log('enasd', this.$root.$i18n.locale)
        console.log("QRcodes",this)
        // this.$root.$i18n.locale = this.$route.params.lang
        // this.brandName = location.hostname.split('.')[1]
        // if(location.hostname.split('.')[1]!='esteelauder'){
        //     this.$root.$i18n.locale = this.$route.params.lang+"_jomalone"
        // }
        this.tpid = this.$route.params.tpid
        this.custId = this.$route.params.custId
        // this.custId = 'EFORM-7'
        this.storeId = this.$route.params.storeId
        this.lang = this.$route.params.lang
        this.brand = this.$route.params.brand
        this.country = this.$route.params.countryPath
        this.txNo = this.$route.params.txNo
        // this.txNo = '123'
        console.log(this.custId)
        console.log('地址信息', this.$route.params.countryPath, this.tpid);
        
        if (this.$route.params.countryPath == 'TH' && this.tpid) {
            //post lineID and consumerID
            console.log('123');
            
            sendLineInfo({
                tpid: this.tpid,
                isOptin: this.$route.params.isOptin
            }).then(res => {
                if (res.success) {
                    Toast.success({
                        message: "Line bind info sent successfully",
                    });
                    this.showBindConfirmMessage = true;
                } else {
                    console.log(res.errMsg)
                    // Toast.fail({
                    //     type: "fail",
                    //     message: res.errMsg,
                    // });
                }
            }).catch(err => {
                console.log(err)
                // Toast.fail({
                //     type: "fail",
                //     message: 'Line bind info send failed',
                // });
            });
        }
    },
    destroyed() {
        this.goBack()
    },
    mounted() {
        JsBarcode('#barcode', this.custId, {
            format: "CODE39",
            displayValue: false,
        })
    },
    computed:{
        tips() {
            let tip = this.$t("barcode.info")
            switch(this.country) {
                case 'VN': 
                    if (this.txNo) {
                        tip = this.$t('barcode.infoVNTxNo')
                    }
                    break
                default :
                    break 
            }
            return `<span>${tip.format(this.brandConfig.display)}</span>`;
        },
    },
    methods: {
        goBack() {
            this.$router.push('/' + this.country + '/' + this.brand + '/Registration/' + this.lang + '?storeId=' + this.storeId)
        },
    }
}
</script>
<style scoped>
.bind-msg-div{
    margin-top: 20px;
}
</style>

